var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "robot-jump-container layout-border",
      class: "lesson-" + _vm.lessonNum,
    },
    [
      _c(
        "div",
        { staticClass: "robot-jump-box position-relative" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
          }),
          _c("div", { staticClass: "robot-jump-area" }, [
            _vm.isShowCheckGif
              ? _c("div", {
                  directives: [
                    {
                      name: "checkgif",
                      rawName: "v-checkgif",
                      value: _vm.checkGif,
                      expression: "checkGif",
                    },
                  ],
                  staticClass: "checkGif",
                  class: { checkGifWrong: !_vm.checkGif },
                })
              : _vm._e(),
            _c(
              "div",
              { ref: "moveGameBox", staticClass: "robot-jump-content" },
              [
                _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
                _c("img", {
                  staticClass: "successBg",
                  attrs: { src: _vm.successBgImg[_vm.step], alt: "" },
                }),
                _c(
                  "div",
                  {
                    ref: "student",
                    staticClass: "robot-img",
                    style: { width: _vm.scrollWidth + "px", height: "100%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "move-wrapper",
                        class: "step-" + _vm.step,
                      },
                      [_c("img", { attrs: { src: _vm.studentImg, alt: "" } })]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "clickArea",
                    staticClass: "click-area",
                    style: { width: _vm.scrollWidth + "px", height: "100%" },
                  },
                  _vm._l(_vm.answerList, function (item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index === _vm.step && !_vm.isLastStep,
                            expression: "index === step && !isLastStep",
                          },
                        ],
                        key: index,
                        staticClass: "item-box step",
                        class: "steps-" + index,
                      },
                      [
                        _c("div", {
                          staticClass: "up-item item",
                          on: {
                            click: function ($event) {
                              return _vm.handleCheck(0, item)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "down-item item",
                          on: {
                            click: function ($event) {
                              return _vm.handleCheck(1, item)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "sound-icon", on: { click: _vm.playAudio } },
            [
              _c("AudioPlayer", {
                attrs: { isPlay: _vm.isStartPlaying, audioUrl: _vm.songUrl },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }