<template>
  <div class="pinyin-puzzle-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <!-- <Star :totalStarNumber="4" :currentIndex="currentIndex" /> -->
    <SceneSwitchThumbnails
      :buttons="buttonList"
      :numBers="currentIndex"
      @changeTheNumbers="changeTheNumbers"
      :menuSize="menuSize"
    />
    <div class="bg-img layout-border">
      <img src="@/assets/img/16-GAMES/G22-drag-abc/background.svg" alt="" />
    </div>
    <div class="pinyin-puzzle-game-box">
      <div class="pinyin-puzzle-game-content">
        <div
          class="drop-area"
          @drop.stop="drop()"
          @dragover.stop="allowDrop($event)"
        />
        <div class="top-area">
          <div class="play-audio" @click.stop="playAudio">
            <!-- 播放音频 -->
            <div>
              <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
            </div>
          </div>
          <div class="puzzle-area" v-if="answerList[currentIndex].type === 1">
            <img
              class="part1"
              :src="answerList[currentIndex].firstImg"
              :class="{ notShow: !answerList[currentIndex].isCorrect }"
            />
            <img class="part2" :class="[fadeAnimation]" :src="answerImageSrc" />
          </div>

          <div class="puzzle-area" v-else>
            <img class="part1" :src="answerList[currentIndex].firstImg" />
            <img
              v-show="answerList[currentIndex].isCorrect"
              class="part2"
              :src="answerList[currentIndex].secondImg"
            />
          </div>
        </div>
        <img class="objectDisplay" :src="answerList[currentIndex].fullImg" />
      </div>
    </div>

    <div class="option-area">
      <img
        class="puzzleImage"
        v-for="(item, index) in optionList[currentIndex]"
        :key="index"
        :src="item.image"
        draggable="true"
        v-show="item.showObj == true"
        @dragstart="startDrag(item.id)"
        @drop.stop="Sdrop($event)"
      />
      <img class="options-background" :src="objectListBackground" />
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";

// import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  components: {
    PageButton,
    // Star,
    AudioPlayer,
    SceneSwitchThumbnails,
  },
  props: {
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
    answerList: {
      type: Array,
      require: true,
      default: () => [],
    },
    menuItems : {
      type: Number,
      require: false,
      default: 4
    },
    menuSize : {
      type: String,
      require: false,
    }
  },
  data() {
    return {
      audioSocketInfo: {},
      dragSocketInfo: {},
      dropSocketInfo: {},
      clickMenuSocketInfo: {},

      isLastStep: false,

      volumeImgSrc: require("@/assets/img/06-Sound/sound-white.svg"),

      currentIndex: 0,
      currentDragIndex: 0,

      objectListBackground: require("@/assets/img/16-GAMES/G22-drag-abc/shape.svg"),
      objectplate: require("@/assets/img/16-GAMES/G22-drag-abc/empty-plate.svg"),
      objectDisplay: "",

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
      isStartPlaying: null,
      songUrl: null,
      buttonList: [
        // {
        //   image: require("@/assets/img/01-Menu/menu-puzzle-1.svg"),
        //   startNumberRange: 0,
        //   endNumberRange: 0,
        // },
        // {
        //   image: require("@/assets/img/01-Menu/menu-puzzle-2.svg"),
        //   startNumberRange: 1,
        //   endNumberRange: 1,
        // },
        // {
        //   image: require("@/assets/img/01-Menu/menu-puzzle-3.svg"),
        //   startNumberRange: 2,
        //   endNumberRange: 2,
        // },
        // {
        //   image: require("@/assets/img/01-Menu/menu-puzzle-1.svg"),
        //   startNumberRange: 3,
        //   endNumberRange: 3,
        // },
      ],
      answerImageSrc: '',
      fadeAnimation: '',
    };
  },
  mounted() {
    this.answerImageSrc = this.answerList[this.currentIndex].secondImg;
    this.$bus.$on("puzzleGameDrag", ({ index }) => {
      this.startDrag(index, true);
    });
    this.$bus.$on("puzzleGameDrop", () => {
      this.drop("socket", true);
    });
    this.$bus.$on("puzzleGameAudio", () => {
      this.playAudio("socket", true);
    });
    this.$bus.$on("puzzleGameClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });


    let currentItName = 1;
    for (let index = 0; index < this.menuItems; index++) {
      this.buttonList.push (
        {
          image: require("@/assets/img/01-Menu/menu-puzzle-"+currentItName+".svg"),
          startNumberRange: index,
          endNumberRange: index,
        },
      );

      if (currentItName >= 3) { // we have only 3 puzzle images. Reset when reaching 3
        currentItName = 1;
      } else {
        currentItName++;
      }
      
    }

  },
  beforeDestroy() {
    this.$bus.$off("puzzleGameDrag");
    this.$bus.$off("puzzleGameDrop");
    this.$bus.$off("puzzleGameAudio");
    this.$bus.$off("puzzleGameClickMenu");
  },
  watch: {
    dragSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003601,
          data: { value },
          text: "puzzlePinyinGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dropSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003602,
          data: { value },
          text: "puzzlePinyinGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003603,
          data: { value },
          text: "puzzlePinyinGame audio socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003604,
          data: { value },
          text: "puzzlePinyinGame menu click socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index);
      this.currentIndex = index;
      if (this.answerList[this.currentIndex].secondImgBefore) {
        this.answerImageSrc = this.answerList[this.currentIndex].secondImgBefore;
      } else {
        this.answerImageSrc = this.answerList[this.currentIndex].secondImg;
      }
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
    },
    foundAnswer() {
      if (this.answerList[this.currentIndex].id == this.currentDragIndex) {

        if (this.answerList[this.currentIndex].secondImgBefore) {
          this.fadeAnimation = 'brightnessUp';
          setTimeout(()=>{
            this.fadeAnimation = 'brightnessDown';
            this.answerImageSrc = this.answerList[this.currentIndex].secondImg;
            
          }, 1500)
        };

        this.objectDisplay = this.optionList[this.currentIndex].image;
        this.optionList[this.currentIndex].showObj = false;
        this.answerList[this.currentIndex].isCorrect = true;
        playCorrectSound(true, false);
        if (this.currentIndex === this.answerList.length - 1) {
          this.isLastStep = true;
          playCorrectSound();
          startConfetti();
        }
      } else {
        playCorrectSound(false);
      }
    },

    nextPage() {
      this.$bus.$emit("nextButton", true);
    },

    playAudio(socket, isFromSocket = false) {
      console.log("++++++++++++++++++");
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
      this.songUrl = this.answerList[this.currentIndex].audioUrl;
    },
    startDrag(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.currentDragIndex = index;
      console.log(this.currentDragIndex);
    },
    drop(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropSocketInfo = {
          value: Math.random(),
        };
      }
      this.foundAnswer();
      this.currentDragIndex = 0;
      //   event.preventDefault();
    },
    Sdrop(event) {
      event.preventDefault();
    },
    allowDrop(event) {
      event.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.puzzle-area {
  width: 60%;
  height: 80%;
  display: flex;
  align-self: center;
  margin-left: 10%;

  .part1 {
    height: 100%;
  }
  .part2 {
    height: 100%;
    margin-left: -18px;
  }
  .notShow {
    opacity: 0;
  }
}
.objectDisplay {
  position: absolute;
  width: 100%;
  bottom: 10%;
  // left: 10%;
}
.plate {
  bottom: 10%;
  position: absolute;
  width: 60%;
  left: 20%;
}
.top-area {
  width: 100%;
  height: 35%;
  left: 0;
  position: absolute;
  background: #224e96;
  text-align: center;
  color: #fff;
  display: flex;
  .pinyin {
    margin-top: 20px;
    font-family: "STHeitiSCPinyin-Light";
  }
}
.options-background {
  position: absolute;
  height: 100%;
}
.option-area {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 15%;
  height: 70%;
  top: 15%;
  left: 10%;
  align-items: center;
  justify-content: space-around;
  .puzzleImage {
    flex: 1;
    display: inline-block;
    width: 60%;
    -webkit-user-drag: auto;
    z-index: 10;
    cursor: pointer;
  }
}
.pinyin-puzzle-game-container {
  //  background: #F7F4E0;

  border-radius: 48px;
  position: relative;
  overflow: hidden;
  position: relative;
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    img {
      border-radius: 56px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.pinyin-puzzle-game-box {
  text-align: -webkit-center;
  position: absolute;
  height: 70%;
  background: #f7f4e0;
  border-radius: 25px;
  top: 15%;
  width: 45%;
  display: flex;
  flex-direction: row;
  left: 30%;
  margin: auto;
  overflow: hidden;
  border-bottom: 10px solid #b5392c;
  .pinyin-puzzle-game-content {
    position: relative;
    width: -moz-fit-content;
    /* background-color: black; */
    width: 100%;
    height: 100%;
    /* left: 10%; */
    border-radius: 10%;
    display: flex;
  }
}

.play-audio {
  width: 8vh;
  height: 8vh;
  align-self: center;
  background: #ce4c3f;
  border-radius: 50%;
  padding: 0;
  margin-left: 10%;
  z-index: 100;
  cursor: pointer;

  div {
    width: 100%;
    height: 100%;
  }
  img {
    border-radius: 50%;
    background: #ce4c3f;
    width: 100%;
  }
}
.play-audio:hover {
  width: 8vh;
  height: 8vh;
  background: #b5392c;
  border-radius: 50%;
  // padding: 17px;
  img {
    border-radius: 50%;
    background: #b5392c;
    width: 100%;
  }
}
.drop-area {
  display: block;
  position: absolute;
  width: 100%;
  height: 40%;
  top: 0;
  left: 0;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
</style>
