var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pinyin-puzzle-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _c("SceneSwitchThumbnails", {
        attrs: {
          buttons: _vm.buttonList,
          numBers: _vm.currentIndex,
          menuSize: _vm.menuSize,
        },
        on: { changeTheNumbers: _vm.changeTheNumbers },
      }),
      _vm._m(0),
      _c("div", { staticClass: "pinyin-puzzle-game-box" }, [
        _c("div", { staticClass: "pinyin-puzzle-game-content" }, [
          _c("div", {
            staticClass: "drop-area",
            on: {
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.drop()
              },
              dragover: function ($event) {
                $event.stopPropagation()
                return _vm.allowDrop($event)
              },
            },
          }),
          _c("div", { staticClass: "top-area" }, [
            _c(
              "div",
              {
                staticClass: "play-audio",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.playAudio.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("AudioPlayer", {
                      attrs: {
                        isPlay: _vm.isStartPlaying,
                        audioUrl: _vm.songUrl,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm.answerList[_vm.currentIndex].type === 1
              ? _c("div", { staticClass: "puzzle-area" }, [
                  _c("img", {
                    staticClass: "part1",
                    class: {
                      notShow: !_vm.answerList[_vm.currentIndex].isCorrect,
                    },
                    attrs: { src: _vm.answerList[_vm.currentIndex].firstImg },
                  }),
                  _c("img", {
                    staticClass: "part2",
                    class: [_vm.fadeAnimation],
                    attrs: { src: _vm.answerImageSrc },
                  }),
                ])
              : _c("div", { staticClass: "puzzle-area" }, [
                  _c("img", {
                    staticClass: "part1",
                    attrs: { src: _vm.answerList[_vm.currentIndex].firstImg },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.answerList[_vm.currentIndex].isCorrect,
                        expression: "answerList[currentIndex].isCorrect",
                      },
                    ],
                    staticClass: "part2",
                    attrs: { src: _vm.answerList[_vm.currentIndex].secondImg },
                  }),
                ]),
          ]),
          _c("img", {
            staticClass: "objectDisplay",
            attrs: { src: _vm.answerList[_vm.currentIndex].fullImg },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "option-area" },
        [
          _vm._l(_vm.optionList[_vm.currentIndex], function (item, index) {
            return _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.showObj == true,
                  expression: "item.showObj == true",
                },
              ],
              key: index,
              staticClass: "puzzleImage",
              attrs: { src: item.image, draggable: "true" },
              on: {
                dragstart: function ($event) {
                  return _vm.startDrag(item.id)
                },
                drop: function ($event) {
                  $event.stopPropagation()
                  return _vm.Sdrop($event)
                },
              },
            })
          }),
          _c("img", {
            staticClass: "options-background",
            attrs: { src: _vm.objectListBackground },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bg-img layout-border" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/16-GAMES/G22-drag-abc/background.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }