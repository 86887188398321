<template>
  <div class="write-stroke-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
      @click.native="startDrawStroke"
    ></PageButton>

    <div class="stroke-page-box">
      <!-- writing button -->
      <SceneSwitchThumbnails
        :buttons="menuButton"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <!-- 眼睛按钮 eye-->
      <div
        v-if="isShowEye"
        class="write-stroke-page-eye-btn"
        @mouseover="eyeIsHover = true"
        @mouseleave="eyeIsHover = false"
        @click="handleClickEyeButton"
      >
        <img
          v-if="!eyeIsHover"
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"
          alt=""
        />
        <img
          v-else
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"
          alt=""
        />
      </div>

      <div class="write-stroke-content">
        <transition name="el-fade-in">
          <div class="write-pinyin-area">
            <div class="write-stroke-content-left">
              <div class="write-stroke-left-content">
                <div class="left-content-border"></div>
                <div class="left-content">
                  <!-- gif 区 -->
                  <div class="gif-img" v-if="isTipPage">
                    <img :src="currentPinyinInfo.tipsImg" alt="" />
                  </div>
                  <!-- 画画区 -->
                  <div class="pinyin-area" @mouseup="endDrawing($event)" v-else>
                    <!-- pinyin img -->
                    <div class="pinyin-img">
                      <img
                        draggable="false"
                        class="stroke-square-img"
                        :src="currentPinyinInfo.pinyinImg"
                        alt=""
                      />
                    </div>
                    <!-- canvas -->
                    <div class="canvas-area">
                      <KidsWhiteBoard ref="drawArea" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  props: {
    pinyinImgList: {
      type: Array,
      require: true,
    },
    strokeTimesList: {
      type: Array,
      require: true,
    },
    buttonList: {
      type: Array,
      require: true,
    },
  },
  created() {
    console.log(this.menuIndex);
    this.currentPinyinInfo = this.pinyinImgList[this.menuIndex];
  },
  data() {
    return {
      nextPinyinSocketInfo: {},
      startDrawSocketInfo: {},
      showTipsSocketInfo: {},
      menuButton: this.buttonList, //menu菜单的头像数据
      numBers: 1,
      currentPinyinInfo: {},

      characterCheckpoints: [
        {
          id: 1,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 2,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 3,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
      ],
      isTipPage: true,
      isShowEye: false,
      isDrawHover: false,
      isShowDrawButton: true,
      eyeIsHover: false,
      isFinishWriting: false,
      isShowHand: false,
      drawing: false,
      // steps: 0,
      menuIndex: 0,
      isLastStep: false,
      drawTimes: 0,
      clickAsideSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
      startDrawStrokeValue: false,
    };
  },
  components: {
    KidsWhiteBoard,
    PageButton,
    SceneSwitchThumbnails,
  },
  mounted() {
    this.$bus.$on("startDrawPinyin", () => {
      this.startDrawStroke("socket", true);
    });
    this.$bus.$on("nextPinyinDraw", () => {
      this.finishedStroke("socket", true);
    });
    this.$bus.$on("showTipsImg", () => {
      this.handleClickEyeButton("socket", true);
    });
    this.$bus.$on("menuClick", ({ index }) => {
      console.log("+++++++++++", index);
      this.changeTheNumbers(index, true);
    });
  },

  beforeDestroy() {
    this.$bus.$emit("startStrokePage", false);
    this.$bus.$off("startDrawPinyin");
    this.$bus.$off("nextPinyinDraw");
    this.$bus.$off("showTipsImg");
    this.$bus.$off("menuClick");
  },
  watch: {
    isShowEye(value) {
      this.$bus.$emit("startStrokePage", value);
    },
    startDrawSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002601,
          data: { value },
          text: "WritePinyinPage点击开始socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    nextPinyinSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002602,
          data: { value },
          text: "WritePinyinPage点击开始socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    showTipsSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002603,
          data: { value },
          text: "WritePinyinPage点击开始socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002604,
          data: { value },
          text: "WritePinyinPage点击开始socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },

  methods: {
    startDrawStroke(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.startDrawSocketInfo = {
          index: Math.random(),
        };
      }
      this.isTipPage = false;
      this.startWritingStroke();
    },

    endDrawing() {
      console.log("结束画画", this.drawTimes);
      // if (this.drawTimesthis.strokeTimesList[(this.menuIndex-1)/2]) {
      this.drawTimes++;
      // }
      if (
        this.strokeTimesList[this.menuIndex] === this.drawTimes ||
        this.strokeTimesList[this.menuIndex] === 1
      ) {
        this.finishedStroke();
        this.drawTimes = 0;
        return;
      }
    },
    // menu菜单栏
    changeTheNumbers(index, isFromSocket = false) {
      this.menuIndex = index - 1;
      console.log(index);
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      this.$bus.$emit("controlCanvas", "clear");
      console.log(this.menuIndex);

      this.currentPinyinInfo = this.pinyinImgList[this.menuIndex];
    },
    finishedStroke(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.nextPinyinSocketInfo = {
          index: Math.random(),
        };
      }
      if (this.menuIndex === this.pinyinImgList.length - 1) {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      } else {
        playCorrectSound(true, false);
      }
    },
    draggedOutOfStroke() {
      console.log("dragged out");
    },
    checkPoint(point) {
      console.log("check point: ", point);
    },

    // 开始写笔画
    startWritingStroke() {
      setTimeout(() => {
        this.isShowDrawButton = false;
        this.isShowEye = true;
      }, 50);
    },
    // 返回最原始page
    handleClickEyeButton(tsocket, isFromSocket = false) {
      if (!isFromSocket) {
        this.showTipsSocketInfo = {
          index: Math.random(),
        };
      }
      this.isTipPage = true;
      // this.isLastStep = false;
      this.isShowEye = false;
      this.$bus.$emit("startStrokePage", this.isShowEye);
    },
    // 下一页
    nextPage() {
      this.$bus.$emit("startStrokePage", false);
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.write-stroke-page-container {
  .stroke-page-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    position: relative;
    .write-stroke-content {
      background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
        no-repeat;
      // padding: 10px 10px 30px 10px;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 58px;
      // display: flex;
      position: relative;
      .write-pinyin-area {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        .write-stroke-content-left {
          // position: absolute;
          position: relative;
          display: flex;
          width: 45%;
          height: 70%;
          justify-content: center;
          align-items: center;
          .write-stroke-left-content {
            position: relative;
            width: 100%;
            height: 100%;
            .left-content-border {
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              position: absolute;
              right: 0;
              bottom: 0;
              background: #224e96;
              border-radius: 30px;
            }
            .left-content {
              color: #000;
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background: #fff;
              border-radius: 30px;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: default;
              .gif-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                img {
                  width: 70%;
                }
              }
              .pinyin-area {
                width: 100%;
                height: 100%;
                position: relative;
                .canvas-area {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                .pinyin-img {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    max-height: 100%;
                    width: 70%;
                    -khtml-user-select: none;
                    -o-user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    user-select: none;
                    -webkit-user-drag: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    // button
    .write-stroke-page-eye-btn {
      position: absolute;
      right: 14px;
      bottom: 26%;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      text-align: right;
      padding-right: 3%;
      img {
        width: 42%;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // margin: auto;
      }
    }
    .button-area {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}
.confettiCanvas {
  z-index: 900;
  position: absolute;
}

.WritingStrokeArea {
  height: 100%;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  .draw-area {
    position: relative;
    .check-point {
      position: absolute;
      background-color: black;
      z-index: 9999;
      pointer-events: all;
    }
  }
}
</style>
