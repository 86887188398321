
<template>
  <div
    class="robot-jump-container layout-border"
    :class="'lesson-' + lessonNum"
  >
    <div class="robot-jump-box position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <div class="robot-jump-area">
        <!-- 失败和成功背景 -->
        <div
          class="checkGif"
          :class="{ checkGifWrong: !checkGif }"
          v-checkgif="checkGif"
          v-if="isShowCheckGif"
        ></div>
        <div class="robot-jump-content" ref="moveGameBox">
          <!-- 背景图 -->
          <img :src="bgImg" alt="" />
          <img :src="successBgImg[step]" alt="" class="successBg" />
          <!-- 机器人 -->
          <div
            class="robot-img"
            ref="student"
            :style="{ width: scrollWidth + 'px', height: '100%' }"
          >
            <div class="move-wrapper" :class="'step-' + step">
              <img :src="studentImg" alt="" />
            </div>
          </div>
          <div
            class="click-area"
            ref="clickArea"
            :style="{ width: scrollWidth + 'px', height: '100%' }"
          >
            <div
              class="item-box step"
              v-for="(item, index) in answerList"
              :key="index"
              :class="'steps-' + index"
              v-show="index === step && !isLastStep"
            >
              <div class="up-item item" @click="handleCheck(0, item)"></div>
              <div class="down-item item" @click="handleCheck(1, item)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="sound-icon" @click="playAudio">
        <!-- <img src="@/assets/img/06-Sound/sound-white.svg" alt="" /> -->
        <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

export default {
  components: {
    PageButton,
    AudioPlayer,
  },
  props: {
    successBgImg: {
      type: Array,
      require: true,
    },
    bgImg: {
      type: String,
      require: true,
    },
    studentImg: {
      type: String,
      require: true,
    },
    lessonNum: {
      type: Number,
      require: true,
    },

    durationArr: {
      type: Array,
      require: true,
    },
    answerList: {
      type: Array,
      require: true,
    },
    audioList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      checkGif: true,
      isShowCheckGif: false,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },

      gameHeight: null,
      gameWidth: null,
      step: 0,
      isAnswerOpen: false,
      isQuestionOpen: false,
      isAction: false,
      scrollDom: null,
      scrollWidth: null,
      distance: 0,
      socketInfo: {},
      audioSocketInfo: {},
      scrollSpeed: 2,
      isStartPlaying: null,
      songUrl: null,
    };
  },

  mounted() {
    this.$bus.$on("robotJump", ({ value, item }) => {
      this.handleCheck(value, item, true);
    });
    this.$bus.$on("robotJumpAudio", () => {
      this.playAudio("socket", true);
    });
    window.addEventListener("resize", this.updateElementPosition);

    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.moveGameBox;
        this.scrollWidth = this.scrollDom.scrollWidth;
        console.log('⭐', this.scrollWidth ); // TO DO Debugging, To delete
      });
    }, 700);
  },
  beforeDestroy() {
    this.$bus.$off("robotJump");
    this.$bus.$off("robotJumpAudio");
    window.removeEventListener("resize", this.updateElementPosition);

  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20022,
          data: { value },
          text: "robotJumpGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002201,
          data: { value },
          text: "robotJumpGame点击语音socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    playAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          index: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
      this.songUrl = this.audioList[this.step];
    },
    handleCheck(value, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketInfo = {
          value,
          item,
          index: Math.random(),
        };
      }
      // console.log(value, item, "++++++++++");
      if (value === item) {
        // 成功
        this.isShowCheckGif = true;
        this.checkGif = true;
        // console.log(this.scrollDom);
        playCorrectSound(true, false);

        this.handleClickBgImg();
        if (this.step === this.answerList.length) {
          setTimeout(() => {
            this.step = this.answerList.length + 1;
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          }, 2000);
        }
      } else {
        //失败动画
        playCorrectSound(false);
        this.isShowCheckGif = true;
        this.checkGif = false;
      }
      setTimeout(() => {
        this.isShowCheckGif = false;
      }, 1000);
    },
    ControlScroll(step) {
      this.scrollMove(this.durationArr[step - 1]);
    },
    scrollMove(num) {
      const speed = this.scrollSpeed;
      let timer = setInterval(() => {
        this.distance += speed;
        this.scrollDom.scrollLeft = this.distance;
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer);
        }
      }, 10);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickBgImg() {
      if (this.step >= this.durationArr.length + 1) {
        return;
      }
      this.step++;
      if (this.step > 1) {
        this.ControlScroll(this.step);
      }
      if (this.step >= this.durationArr.length + 1) {
        return;
      }
    },
    updateElementPosition(){
      this.scrollWidth = 0;

    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.moveGameBox;
        this.scrollWidth = this.scrollDom.scrollWidth;
      });
    }, 1000);

      return;
      setTimeout(()=> {
      let _this = this;
        this.clickAreaScrollDom = this.$refs.clickArea;
        this.scrollWidth = this.clickAreaScrollDom.clientWidth;
        console.log('⭐', this.scrollWidth ); // TO DO Debugging, To de
      }, 2000)
        // alert(this.scrollDom.scrollWidth)
    }
  },
};
</script>
<style scoped lang="scss">
.robot-jump-container {
  position: relative;
  .robot-jump-box {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .panda-error {
      width: 22%;
      position: absolute;
      bottom: -1%;
      left: 10%;
      z-index: 5;
      img {
        width: 100%;
      }
    }
    .panda-correct {
      width: 30%;
      position: absolute;
      bottom: -1%;
      left: 10%;
      z-index: 5;
      img {
        width: 100%;
      }
    }
    .sound-icon {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 8%;
      left: 10%;
      z-index: 11;
      background: #224e96;
      border-radius: 50%;
      img {
        cursor: pointer;
        width: 100%;
      }
    }
    .robot-jump-area {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border-radius: 58px;
      position: relative;
      .robot-jump-content {
        height: 100%;
        position: relative;
        border-radius: 58px;
        overflow-x: scroll;
        img {
          height: 100%;
        }
        .successBg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
        }
        .click-area {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 11;
          .step {
            position: relative;
            width: 100%;
            height: 100%;
          }
          .item {
            width: 121px;
            height: 59px;
            position: absolute;
            // background: #000;
            cursor: pointer;
            @media screen and (min-height: 700px) {
                width: 190px;
                height: 80px;
            }
            @media screen and (min-height: 900px) {
                width: 300px;
                height: 80px;
            }
          }
        }
        .picture-content-rocket-content {
          height: 22%;
          position: absolute;
          z-index: 1001;
          img {
            height: 100%;
          }
        }
        .robot-img {
          position: absolute;
          bottom: 0%;
          left: 0%;

          .move-wrapper {
            display: inline-block;
            position: absolute;
            bottom: 20%;
            left: 1%;
            transition: all 2s;
            img {
              width: 45%;
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}

.lesson-7, .lesson-55, .lesson-39, .lesson-63, .lesson-15, .lesson-31 {
  .steps-0 {
    .up-item {
      bottom: 35%;
      // left: 9%;
      // left: 290px;
        left: calc(34vh*.9);
    }
    .down-item {
      bottom: 17%;
      // left: 9%;
        left: calc(36vh*.9);
    }
  }
  .step-1 {
    bottom: 44% !important;
    left: 9.5% !important;
  }
  .steps-1 {
    .up-item {
      bottom: 38%;
      left: calc(72vh*.9);
    }
    .down-item {
      bottom: 25%;
      left: calc(86vh*.9);
    }
  }
  .step-2 {
    bottom: 46% !important;
    left: 21% !important;
  }
  .steps-2 {
    .up-item {
      bottom: 68%;
      left: calc(122vh*.9);
    }
    .down-item {
      bottom: 54%;
      left: calc(127vh*.9);
    }
  }
  .step-3 {
    bottom: 62% !important;
    left: 34% !important;
  }
  .steps-3 {
    .up-item {
      bottom: 16%;
      left: calc(140vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(122vh*1.1);
      }
    }
    .down-item {
      bottom: 8%;
        left: calc(150vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(141vh*1.1);
      }
    }
  }
  .step-4 {
    bottom: 17% !important;
    left: 43% !important;
  }

  .steps-4 {
    .up-item {
      bottom: 53%;
      left: calc(216vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(190vh*1.1);
      }
    }
    .down-item {
      bottom: 42%;
      left: calc(205vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(176vh*1.1);
      }
    }
  }
  .step-5 {
    bottom: 61% !important;
    left: 58% !important;
  }

  .steps-5 {
    .up-item {
      bottom: 25%;
      left: calc(266vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(288.3vh*.9);
      }
    }
    .down-item {
      bottom: 8%;
      left: calc(280vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(245vh*1.1);
      }
    }
  }
  .step-6 {
    bottom: 17% !important;
    left: 76% !important;
  }

  .steps-6 {
    .up-item {
      bottom: 51%;
      left: calc(325vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(283vh*1.1);
      }
    }
    .down-item {
      bottom: 38%;
      left: calc(334vh*.9);
      @media screen and (min-height: 1000px) {
        left: calc(298vh*1.1);
      }
    }
  }
  .step-7 {
    bottom: 58% !important;
    left: 87% !important;
  }
  .step-8 {
    bottom: 69% !important;
    left: 90.5% !important;
  }
}

.lesson-7 {
  .robot-img {
    .step-5 {
      bottom: 48% !important;
      left: 55% !important;
    }
    .step-6 {
      bottom: 31% !important;
      left: 72% !important;
    }
  }
}
.lesson-15 {
  .robot-img {
    .step-4 {
      bottom: 26% !important;
      left: 39% !important;
    }
    .step-5 {
      bottom: 49% !important;
      left: 54% !important;
    }
  }
}

.lesson-55 {
  .robot-img {

  .step-1 {
      bottom: 23% !important;
  }
  .step-7 {
      bottom: 45% !important;
      left: 91% !important;
  }
  }
  .steps-2 .item {
    width: 190px !important;
    &.up-item {
      left: calc(108vh*.9);
    }
    &.down-item {
      left: calc(114vh*.9);
    }
  }
  .steps-3 .item {
    width: 200px !important;
    &.up-item {
      left: calc(129vh*.9);
    }
    &.down-item {
      left: calc(143vh*.9);
    }
  }
  .steps-4 .item {
    width: 190px !important;
    &.up-item {
      left: calc(199vh*.9);
    }
    &.down-item {
      left: calc(181vh*.9);
    }
  }
  .steps-5 .item {
    // width: 190px !important;
    &.up-item {
      left: calc(252vh*.9);
    }
    &.down-item {
      left: calc(267vh*.9);
    }
  }
  .steps-6 .item {
    // width: 190px !important;
    &.up-item {
      left: calc(304vh*.9);
    }
    &.down-item {
      left: calc(323vh*.9);
    }
  }
}

.lesson-39 {
  .robot-img {
    .step-2 {
      bottom: 31% !important;
      left: 24% !important;
    }
    .step-3 {
      bottom: 74% !important;
      left: 33% !important;
    }
    .step-5 {
      bottom: 49% !important;
      left: 54% !important;
    }
    .step-6 {
      bottom: 33% !important;
      left: 72% !important;
    }
    .step-7 {
      bottom: 48% !important;
      left: 90% !important;
    }
  }
}


.lesson-63 {
  .robot-img {
    .step-3 {
      bottom: 74% !important;
      left: 33% !important;
    }
    .step-5 {
      bottom: 49% !important;
      left: 54% !important;
    }
    .step-6 {
      bottom: 32% !important;
      left: 73% !important;
    }
    .step-7 {
      bottom: 46% !important;
      left: 92% !important;
    }
  }
}

.lesson-31 {
  .robot-img {
    .step-3 {
      bottom: 74% !important;
      left: 33% !important;
    }
    .step-7 {
      bottom: 46% !important;
      left: 92% !important;
    }    
  }
}
</style>